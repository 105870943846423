import { useLocation, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import styles from "./SessionTimeout.module.scss";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userSelector } from "@podcasts/store/user";
import { logOut } from "@podcasts/store/user";

const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 99,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.6)"
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(254, 239, 201, 0.9)",
    with: "60%",
    border: "none",
    padding: 0,
    borderRadius: "16px",
  },
};

export const SessionTimeout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const handleLogin = async () => {
    await dispatch(logOut());
    navigate("/login");
  }
  const IGNORE_PATHS = ["/login", "/register", "/forgot-password", "/reset-password"];
  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  const { loggedIn, user } = useSelector(userSelector) as any;

  useEffect(() => {
    const token = user?.token;
    const issued_at = user?.issued_at;
    const expires_in = user?.expires_in;
    const now = Date.now() / 1000;
    if (loggedIn && token && now > issued_at + expires_in && !IGNORE_PATHS.includes(location.pathname)) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [location, loggedIn, user]);

  return (
    <Modal
      isOpen={modalIsOpen}
      // className={styles["session-modal"]}
      style={customStyles}
      // overlayClassName={styles["session-overlay"]}
    >
      <div className={styles["session-timeout"]}>
        <h1 className={styles["session-title"]}>Your session has timed out</h1>
        <p className={styles["description"]}>Please login again to restart the app.</p>
        <button className={styles["login-btn"]} onClick={handleLogin}>Login</button>
      </div>
    </Modal>
  );
}